import stringify from './stringify';

const get = async function get(url) {
  return fetch(url);
};

const post = async function post(url, data) {
  return fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    body: stringify(data),
  });
};

export default {
  get,
  post,
};