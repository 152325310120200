export default function stringify(obj) {
  return Object.keys(obj)
    .reduce((acc, next) => {
      const data = obj[next];

      if (Array.isArray(data)) {
        const arr = data.map(item => {
          return `${encodeURIComponent(next)}[]=${encodeURIComponent(item)}`;
        });

        return [...acc, ...arr];
      }

      return [...acc, `${encodeURIComponent(next)}=${encodeURIComponent(data)}`];
    }, [])
    .join('&');
};
