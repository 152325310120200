import React from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {
  Typography,
  Button,
} from '@material-ui/core';
import App from './App';
import Logo from '../components/Logo';

function Contact(props) {
  const history = useHistory();
  return (
    <App withAnimation>
      <Logo />
      <Typography className='animated-text'>
        Escolha uma das opções para entrar em contato
      </Typography>
      <div style={{paddingTop: 20}} className='animated-text'>
        <Button className='contact-button' variant='contained' color='primary' size='small' onClick={() => window.location.href = 'https://dash.carloshb.com.br'}>Sou cliente</Button>
        <Button className='contact-button' variant='contained' color='primary' size='small' onClick={() => history.push('/contato/novo-cliente')}>Não sou cliente</Button>
      </div>
    </App>
  );
};

export default withRouter(Contact);
