import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';

import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
} from '@material-ui/core';

import {
    AccountTree as AccountTreeIcon,
    Person as PersonIcon,
    Layers as LayersIcon,
    ContactPhone as ContactIcon,
} from '@material-ui/icons';

const listItemStyle = {paddingBottom: 4, paddingTop: 0};
const linkStyle = {textDecoration: 'none', color: '#2c3e50'};

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
            items: [],
        };

        this.sideList = this.sideList.bind(this);
    }

    isSelected(path) {
        return window.location.href.indexOf(path) !== -1;
    }

    sideList(side) {
        return (
            <div
                style={{width: '60px', overflow: 'hidden'}}
                role='presentation'
                onClick={() => ({})}
                onKeyDown={() => ({})}
            >
                <List disablePadding>
                    <Link to='/sobre' style={linkStyle}>
                        <Tooltip title={`Ir para sobre Carlos Bernardes`}>
                            <ListItem style={listItemStyle} selected={this.isSelected('/sobre')} button key={'Ir para sobre Carlos Bernardes'}>
                                <ListItemIcon>
                                    <PersonIcon className='menu--icon' />
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    </Link>
                    <Link to='/stack' style={linkStyle}>
                        <Tooltip title={`Ir para stack`}>
                            <ListItem style={listItemStyle} selected={this.isSelected('/stack')} button key={'Ir para stack'}>
                                <ListItemIcon>
                                    <LayersIcon className='menu--icon' />
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    </Link>
                    <Link to='/linha-temporal' style={linkStyle}>
                        <Tooltip title={`Ir para linha do tempo`}>
                            <ListItem style={listItemStyle} selected={this.isSelected('/linha-temporal')} button key={'Ir para linha do tempo'}>
                                <ListItemIcon>
                                    <AccountTreeIcon className='menu--icon' />
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    </Link>
                    <Link to='/contato' style={linkStyle}>
                        <Tooltip title={`Ir para contato`}>
                            <ListItem style={listItemStyle} selected={this.isSelected('/contato')} button key={'Ir para contato'}>
                                <ListItemIcon>
                                    <ContactIcon className='menu--icon' />
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    </Link>
                </List>
            </div>
        );
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({show: nextProps.show});
    }

    render() {
        const {updateHandler} = this.props;
        return (
            <Drawer className='menu' style={{overflowY: 'hidden'}} variant='permanent' open={true} onClose={updateHandler}>
                {this.sideList('left')}
            </Drawer>
        );
    }
}

export default withRouter(Menu);