import React, {useState} from 'react';
import PropTypes from 'prop-types';

function TextArea({
  value = '',
  name,
  onChange,
  numRows,
  label,
  minLength = 0,
  maxLength = 300,
  withCounter = false,
  error = true,
}) {
  const [content, setContent] = useState(value);
  const [focused, setFocused] = useState(false);

  const onType = e => {
    const {value} = e.target;

    if (value.length <= maxLength) {
      setContent(value);
      onChange && onChange(e);
    }
  }

  const onFocusIn = e => {
    setFocused(true);
  };

  const onFocusOut = e => {
    setFocused(false);
  };

  return (
    <div className={`text-area MuiFormControl-root ${error ? 'with-error' : ''}`}>
      {
        label &&
        <div className={focused ? 'title MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-focused' : 'title MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink'}>
          {label}
        </div>
      }
      <textarea
        rows={numRows}
        onChange={onType}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        value={content}
        onFocus={onFocusIn}
        onBlur={onFocusOut}
      />
      {
        withCounter &&
        <div className='counter'>{maxLength - content.length} caracteres restantes</div>
      }
    </div>
  );
}

TextArea.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  numRows: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  withCounter: PropTypes.bool,
  error: PropTypes.bool,
};

export default TextArea;
