import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {
  Container,
  Grid,
} from '@material-ui/core';
import Menu from '../components/Menu';

function App(props) {
  useEffect(() => {
    if (props.history.location.pathname === '/') {
      props.history.push('/sobre');
    }
  });

  return (
    <Container disableGutters className='App'>
        <Menu />
        <Container disableGutters>
          <Grid className={props.withAnimation ? 'content animated' : 'content'} container alignItems='center' direction='column'>
            {props.children}
          </Grid>
        </Container>
    </Container>
  );
}

export default withRouter(App);
