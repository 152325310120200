import React from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import App from './App';
import Logo from '../components/Logo';

export default function Timeline(props) {
  return (
    <App withAnimation>
      <Logo />

      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2021 - presente"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Especialista em Relacionamento Digital</h3>
          <h4 className="vertical-timeline-element-subtitle">Oi S/A</h4>
          <p>
            Atuo no time de Soluções Digitais, squad MinhaOi 3.0
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2021 - presente"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">Desenvolvimento Web</h3>
          <h4 className="vertical-timeline-element-subtitle">Anhanguera Educacional</h4>
          <p>
            Curso Superior de Tecnologia
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2018-2019"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Desenvolvedor</h3>
          <h4 className="vertical-timeline-element-subtitle">B2W Digital</h4>
          <p>
            Atuei no time do Catálogo, inicialmente produzindo features e fixes para o frontend das marcas, na prova de conceito para a feature Q&A, no desenvolvimento de uma library para disparo de métricas centralizado e no Back for Front do Catálogo.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2016-2018"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Desenvolvedor Full Stack</h3>
          <h4 className="vertical-timeline-element-subtitle">Atelier Design</h4>
          <p>
            Criação e manutenção de sistemas web e sites institucionais, utilizando como principais ferramentas: PHP, Javascript, Sass, JQuery, etc...
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2016"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">Análise e Desenvolvimento de Sistemas</h3>
          <h4 className="vertical-timeline-element-subtitle">FATEC Prof Waldomiro May</h4>
          <p>
            Curso Superior de Tecnologia
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2015 - Atual"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Desenvolvedor</h3>
          <h4 className="vertical-timeline-element-subtitle">Freelancer</h4>
          <p>
            Desenvolvimendo de Sistemas Web completos, utilizando diversas tecnologias como: React, NodeJS, Laravel, Magento, etc...
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2013"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Desenvolvedor Java</h3>
          <h4 className="vertical-timeline-element-subtitle">Freelancer</h4>
          <p>
            Desenvolvimento de aplicações desktop Java, utilizando JSwing.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2012-2013"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">Informática</h3>
          <h4 className="vertical-timeline-element-subtitle">ETEC Prof José Sant'ana de Castro</h4>
          <p>
            Curso Técnico
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </App>
  );
}