import React from 'react';
import * as icons from '../icons';

export default function Icon({name, description = ''}) {
  const SVGIcon = icons[name];

  return <div className='icon'>
    <img src={SVGIcon} alt={description}/>
  </div>;
}
