import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Typography,
  Tooltip,
  Grid,
  Button,
} from '@material-ui/core';
import App from './App';
import Logo from '../components/Logo';
import Icon from '../components/Icon';

export default function Stack(props) {
  const history = useHistory();

  return (
    <App withAnimation>
      <Logo />
      <Typography variant='h6' style={{marginBottom: 20}}>Stack principal</Typography>
      <Grid container justify='space-evenly'>
        <Tooltip title='Linguagem Javascript'>
          <Typography><Icon name='javascript2' /></Typography>
        </Tooltip>
        <Tooltip title='Linguagem PHP'>
          <Typography><Icon name='php' /></Typography>
        </Tooltip>
        <Tooltip title='Biblioteca ReactJS'>
          <Typography><Icon name='react2' /></Typography>
        </Tooltip>
        <Tooltip title='Framework Laravel'>
          <Typography><Icon name='laravel' /></Typography>
        </Tooltip>
        <Tooltip title='Pré processador de CSS SASS'>
          <Typography><Icon name='sass' /></Typography>
        </Tooltip>
        <Tooltip title='Gerenciamento de estado Redux'>
          <Typography><Icon name='redux' /></Typography>
        </Tooltip>
        <Tooltip title='Conteinerização com Docker'>
          <Typography><Icon name='docker' /></Typography>
        </Tooltip>
      </Grid>
      <Typography variant='h6' style={{marginBottom: 20, marginTop: 20}}>Outros conhecimentos</Typography>
      <Grid container justify='space-evenly'>
        <Tooltip title='Linguagem NodeJS'>
          <Typography><Icon name='nodejs' /></Typography>
        </Tooltip>
        <Tooltip title='Linguagem Python'>
          <Typography><Icon name='python' /></Typography>
        </Tooltip>
        <Tooltip title='Nginx'>
          <Typography><Icon name='nginx' /></Typography>
        </Tooltip>
        <Tooltip title='Apache'>
          <Typography><Icon name='apache' /></Typography>
        </Tooltip>
        <Tooltip title='Magento'>
          <Typography><Icon name='magento' /></Typography>
        </Tooltip>
        <Tooltip title='Wordpress'>
          <Typography><Icon name='wordpress' /></Typography>
        </Tooltip>
        <Tooltip title='Linguagem Java'>
          <Typography><Icon name='java' /></Typography>
        </Tooltip>
        <Tooltip title='Biblioteca Jquery'>
          <Typography><Icon name='jquery' /></Typography>
        </Tooltip>
      </Grid>
      <br />
      <Button className='contact-button' variant='contained' color='primary' size='small' onClick={() => history.push('/linha-temporal')}>Veja a linha do tempo</Button>
    </App>
  );
}
