import request from './request';

export default class FormHelper {
  constructor(form, callback, validation = {}) {
    this.form = form;
    this.callback = callback;
    this.validation = validation;

    this.hasErrors = this.hasErrors.bind(this);
    this.hasVisualError = this.hasVisualError.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async submit(url) {
    if (this.hasErrors()) {
      return new Promise(((resolve, reject) => {
        reject('Preencha os campos corretamente.');
      }));
    }

    return request.post(url, this.form);
  }

  hasErrors() {
    const errors = this.validation.rules(this.form);

    return Object.keys(this.form).reduce((acc, next) => {
      if (acc) return acc;

      return errors[next];
    }, false);
  }

  hasVisualError(field) {
    const errors = this.validation.rules(this.form);

    return errors[field] && this.form[field].length > 0;
  }

  onChange(field) {
    return ({target:{value}}) => {
      const newForm = {
        ...this.form,
        [field]: value,
      };

      this.callback(newForm);
    };
  };
};
