import React from 'react';

export default function Logo(props) {
  return (
    <div className='logo'>
      <div className='arrow-right'>></div>
      <div className='letters'>
        <span className='carlos'>carlos</span>
        <span className='hb'>hb</span>
      </div>
      <div className='underline'>_</div>
    </div>
  );
}