import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import Profile from './containers/Profile';
import Stack from './containers/Stack';
import App from './containers/App';
import Logo from './components/Logo';
import Timeline from './containers/Timeline';
import Contact from './containers/Contact';
import ContactForm from './containers/ContactForm'

export default function Router(props) {

    return (
        <BrowserRouter>
            <Switch>
                <Route path='/sobre' exact>
                    <Profile />
                </Route>
                <Route path='/stack' exact>
                    <Stack />
                </Route>
                <Route path='/linha-temporal' exact>
                    <Timeline />
                </Route>
                <Route path='/contato' exact>
                    <Contact />
                </Route>
                <Route path='/contato/novo-cliente' exact>
                    <ContactForm />
                </Route>
                <Route path='*'>
                    <App>
                        <Logo />
                        <p>Página não encontrada</p>
                    </App>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}