import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, FormControl, Grid, Input, InputAdornment, InputLabel, Paper, Typography} from '@material-ui/core';
import {LabelImportant as SubjectIcon, Mail as MailIcon, Person as PersonIcon} from '@material-ui/icons';
import TextArea from '../components/TextArea';
import FormHelper from '../helpers/form';
import Logo from '../components/Logo'
import App from './App'

const ContactForm = function ContactForm() {
  const [form, setForm] = useState({
    'form-name': 'contact',
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const {
    onChange,
    hasVisualError,
    hasErrors,
    submit,
  } = new FormHelper(form, setForm, {
    rules: obj => ({
      name: obj.name.length < 5,
      email: obj.email.match(/([a-zA-Z0-9]|\.|_)@([a-zA-Z0-9]+)\.([a-z]{2,3})(\.[a-z]{2,3})?/) === null,
      subject: obj.subject.length < 10,
      message: obj.message.length < 30,
    }),
  });

  const onFormSubmit = async e => {
    e.preventDefault();

    try {
      await submit('/');

      alert('Enviado com sucesso!');
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <App withAnimation>
      <Logo />
      <Typography className='animated-text'>
        Preencha o formulário e aguarde contato via e-mail, se preferir deixe seu telefone no corpo da mensagem
      </Typography>
      <Paper className='panel full-width animated-text'>
        <form name='contact' data-teflify='true' method='POST' onSubmit={onFormSubmit}>
          <input type='hidden' name='form-name' value='contact' />
          <Grid container direction='column'>
            <FormControl
              className='margin-bottom'
            >
              <InputLabel
                htmlFor='input-name'
              >
                Seu Nome
              </InputLabel>
              <Input
                id='input-name'
                name='name'
                onChange={onChange('name')}
                startAdornment={
                  <InputAdornment position='start'>
                    <PersonIcon className='input-icon' />
                  </InputAdornment>
                }
                error={hasVisualError('name')}
              />
            </FormControl>
            <FormControl className='margin-bottom'>
              <InputLabel
                type='email'
                htmlFor='input-mail'
              >
                Seu e-mail
              </InputLabel>
              <Input
                id='input-mail'
                onChange={onChange('email')}
                name='email'
                startAdornment={
                  <InputAdornment position='start'>
                    <MailIcon className='input-icon' />
                  </InputAdornment>
                }
                error={hasVisualError('email')}
              />
            </FormControl>
            <FormControl className='margin-bottom'>
              <InputLabel
                type='subject'
                htmlFor='input-subject'
              >
                Assunto
              </InputLabel>
              <Input
                id='input-subject'
                onChange={onChange('subject')}
                name='subject'
                startAdornment={
                  <InputAdornment position='start'>
                    <SubjectIcon className='input-icon' />
                  </InputAdornment>
                }
                error={hasVisualError('subject')}
              />
            </FormControl>
            <TextArea
              name='message'
              label='Mensagem'
              value={form.message}
              onChange={onChange('message')}
              maxLength={500}
              numRows={10}
              error={hasVisualError('message')}
              withCounter
            />
          </Grid>
          {hasErrors() ? <Typography variant='caption' style={{color: '#3498db'}}>Preencha todos os campos para poder enviar</Typography> : ''}
          <Button type='submit' disabled={hasErrors()} size='small' style={{float: 'right', marginTop: 20}} color='primary' variant='contained'>Enviar</Button>
        </form>
      </Paper>
    </App>
  );
};

export default withRouter(ContactForm);
