import React from 'react';
import {useHistory} from 'react-router-dom';
import App from './App';
import {
  Typography,
  Avatar, Button,
} from '@material-ui/core';
import avatar from '../images/carlos.jpg';

import Logo from '../components/Logo';

export default function Profile(props) {
  const history = useHistory();
  return (
    <App>
      <Logo />
      <Avatar className='avatar' alt='Carlos Henrique Bernardes' src={avatar} />
      <Typography className='animated-text' variant='h6'>Carlos Bernardes, 26</Typography>
      <Typography className='animated-text' variant='caption'>Fullstack Web Developer</Typography>
      <Typography className='animated-text description'>
        Desde os 12 anos me apaixonei por programação, aprendendo inicialmente a programar com a linguagem C.
        Alguns anos se passaram e fui me interessando por programar para Web, porém ao começar um curso técnico em informática voltei os esforços para Desktop, focando em Java(swing).
        Logo identifiquei que meu real interesse era a Web, então decidi focar os estudos em BackEnd, onde desenvolvi a base da minha carreira.
        Há 5 anos decidi aperfeiçoar meus conhecimentos em FrontEnd, e logo após alguns freelas comecei a trabalhar na Atelier Design.
        Após entrar na B2W tive a oportunidade de fazer back for front e coleta/disparo de métricas.
        Hoje desenvolvo produtos completos e funcionalidades trabalhando como freelancer.
      </Typography>
      <br />
      <Button className='animated-text contact-button' variant='contained' color='primary' size='small' onClick={() => history.push('/stack')}>Conheça minha stack de trabalho</Button>
    </App>
  );
}
